<template>
	<div class="pageContainer targetPage">
		<el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1" style="flex-wrap:wrap;">

				<span style="margin: 0 0 10px 0;">选择班级：</span>
				<tchtree v-model="deptId" @change="getStudents" style="margin: 0 10px 10px 0;"></tchtree>
			</div>

		</el-row>
		<div class="dividerBar"></div>

		<div style="width：100%">

			<div id="chartBox" style="height: 500px;"></div>

		</div>




	</div>
</template>
<script>

import tchtree from "../com/tchTree.vue";

export default {
	name: "dept",
	components: {
		tchtree
	},
	data() {
		return {
			deptId: ""

		};
	},

	mounted() {
		this.getStudents()
	},
	methods: {

		getStudents(e) {
			this.$http.post("/api/byqx_self_tongji",{class_ids:this.deptId}).then(res => {
				this.initChart(res.data)
			})
		},
		initChart(dataList) {

			dataList.map(e=>{
				e.rate = Math.round(e.join_count/e.total_count*1000)/10
			})
			let dataAxis = this._lo.map(dataList, 'class_name')

			let data = this._lo.map(dataList, 'rate')


			let option = {
				title: {
					text: '班级完善率(%)',
				},
				grid: { containLabel: true, },
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'line' // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: function (params) {

						if (params.length > 0) {
							var result = params[0].name + "<br>";
							params.forEach(function (item) {
							
								result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>共${dataList[item.dataIndex].total_count}人，参与${dataList[item.dataIndex].join_count}人，${item.value}%</br>`
							});
							return result;
						}
					},
				},
				xAxis: {
					data: dataAxis,
					type: 'category',
					axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
					axisTick: { show: true },
					axisLine: { show: true },
					z: 10
				},
				yAxis: {
					max: 100,//最大值
					min: 0,//最小值
					interval: 10,//间隔
					axisLine: { show: true },
					axisTick: { show: true },
					axisLabel: { color: '#000' }
				},
				series: [
					{
						type: 'bar',
						barMaxWidth: 30,
						itemStyle: {
							color: '#33a9ff',
							// color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
							//   { offset: 0, color: '#83bff6' },
							//   { offset: 0.5, color: '#188df0' },
							//   { offset: 1, color: '#188df0' }
							// ])
						},
						emphasis: {
							itemStyle: {
								color: '#33a9ff',
								// color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
								//   { offset: 0, color: '#2378f7' },
								//   { offset: 0.7, color: '#2378f7' },
								//   { offset: 1, color: '#83bff6' }
								// ])
							}
						},
						data: data,

						label: {
							show: true,
							position: 'inside',
							// formatter: function (params) { //标签内容
							//   return params.value + '%'
							// },
						},
					}
				],
			};

			let myChart = this.$echarts.init(document.getElementById('chartBox'));
			myChart.setOption(option)

		},

	}
};
</script>
<style scoped ></style>
  